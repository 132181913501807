* {
  font-family: 'Proxima-Nova';
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* disable callouts/highlights on IOS - https://dev.to/oskarlarsson/designing-native-like-progressive-web-apps-for-ios-510o */
  -webkit-touch-callout: none; 
  -webkit-tap-highlight-color: transparent;
}

.cookieconsent a:link { color: #E80064; text-decoration: none; font-weight: 600 }
.cookieconsent a:visited { color: #E80064; text-decoration: none; font-weight: 600 }
.cookieconsent a:hover { color: #E80064; text-decoration: underline; font-weight: 600 }
.cookieconsent a:active { color: #E80064; text-decoration: none; font-weight: 600 }

a.primlink:link { color: #7243dd; text-decoration: none; }
a.primlink:visited { color: #7243dd; text-decoration: none; }
a.primlink:hover { color: #E80064; text-decoration: underline; }
a.primlink:active { color: #7243dd; text-decoration: none;  }

a.seclink:link { color: #E80064; text-decoration: none; }
a.seclink:visited { color: #E80064; text-decoration: none; }
a.seclink:hover { color: #7243dd; text-decoration: underline; }
a.seclink:active { color: #E80064; text-decoration: none;  }

a.boldlink:link { color: #7243dd; font-weight: 700; text-decoration: none; }
a.boldlink:visited { color: #7243dd; font-weight: 700; text-decoration: none; }
a.boldlink:hover { color: #E80064; font-weight: 700; text-decoration: underline; }
a.boldlink:active { color: #7243dd; font-weight: 700; text-decoration: none;  }

span.seclink { color: #E80064; text-decoration: none; }
span.seclink:hover { color: #E80064; text-decoration: underline; cursor: pointer; }

span.greylink { color: #827f8f; text-decoration: none; }
span.greylink:hover { color: #E80064; text-decoration: none; cursor: pointer; font-weight: 700; }

.primaryAddSelection ::selection {
	background-color: #B49BEC;
	color: #FFFFFF;
}
.primaryAddSelection ::moz-selection {
	background-color: #B49BEC;
	color: #FFFFFF;
}

.primaryDelSelection ::selection {
	background-color: #D3C3F3;
	/*text-decoration: line-through;*/
	color: #7243dd;
}

.primaryDelSelection ::moz-selection {
	background-color: #D3C3F3;
	/*text-decoration: line-through;*/
	color: #7243dd;
}

.secondaryAddSelection ::selection {
	background-color: #F38CB2;
	color: #FFFFFF;
}
.secondaryAddSelection ::moz-selection {
	background-color: #F38CB2;
	color: #FFFFFF;
}

.secondaryDelSelection ::selection {
	background-color: #F7BAD1;
	/*text-decoration: line-through;*/
	color: #E80064;
}

.secondaryDelSelection ::moz-selection {
	background-color: #F7BAD1;
	/*text-decoration: line-through;*/
	color: #E80064;
}

span.typewriter {
	position: relative;
	color: #E80064;
	border-bottom: 1px dotted #E80064;
}

span.typewriter span {
	position: absolute;
	top: 0;
	right: 0;
	background: #ffffff;
	border-bottom: 1px solid #ffffff;
	width: 100%;
	border-left: .1em solid transparent;
	-webkit-animation: typing 6s steps(18) infinite, cursor 1s infinite;
	animation: typing 6s steps(18) infinite, cursor 1s infinite;
}

/* Typewriter Animation */
@-webkit-keyframes typing{
	0% { width: 100%;}
	50% { width: 0;}
	100% { width: 0;}
}
@-webkit-keyframes cursor{
	50% { border-color: #E80064; border-bottom: 1px solid #ffffff;}
}
@keyframes typing{
	0% { width: 100%;}
	50% { width: 0;}
	100% { width: 0;}
}
@keyframes cursor{
	50% { border-color: #E80064; border-bottom: 1px solid #ffffff;}
}

/* Pagination for the PDF Viewer */
.react-pdf__Document {
  position: relative;
}

.react-pdf__Document:hover .page-controls {
	opacity: 1;
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 24px 0px;
  border-radius: 20px;
}

.NegoCardEditor-editor .NegoCardEditor-secTitle {
    font-weight: 700;
	font-size: 24px;
	margin-bottom: 6px;
}

.NegoCardEditor-editor .NegoCardEditor-ssecTitle {
    font-weight: 600;
	font-size: 20px;
	margin-bottom: 6px;
}

.NegoCardEditor-editor .NegoCardEditor-clauseTitle {
    font-weight: 700;
	margin-bottom: 6px;
}

.NegoCardEditor-editor .NegoCardEditor-clausePar {
    font-weight: 300;
}

.RichEditor-root {
height: 100%;
}

.RichEditor-editor {
cursor: text;
font-size: 16px;
padding: 0px;
margin: 0px 0px 0px 0px;
color: #2b2936;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
	padding-top: 30px;
}

.RichEditor-hidePlaceholder .public-DraftEditor-content {
	padding: 0px;
}
.public-DraftEditorPlaceholder-root {
	color: #aca9ba;
	font-size: 36px;
}

.RichEditor-editor .public-DraftEditor-content {
	min-height: 450px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
}

.RichEditor-editor .RichEditor-agrTitle .numbering,
.RichEditor-editor .RichEditor-exhTitle .numbering,
.RichEditor-editor .RichEditor-agrTitle .clauseTitle,
.RichEditor-editor .RichEditor-exhTitle .clauseTitle {
	color: #2b2936;
	font-weight: 700;
    background-color: transparent;
}

.RichEditor-editor .RichEditor-secTitle1 .clauseTitle,
.RichEditor-editor .RichEditor-secTitle2 .clauseTitle {
	color: #2b2936;
	font-weight: 700;
    background-color: transparent;
	padding: 0px 0px 0px 0px;
	margin-right: 0px;
}

.RichEditor-editor .RichEditor-preamble .numbering,
.RichEditor-editor .RichEditor-clausePar1 .numbering,
.RichEditor-editor .RichEditor-clausePar2 .numbering,
.RichEditor-editor .RichEditor-clausePar3 .numbering,
.RichEditor-editor .RichEditor-clausePar4 .numbering,
.RichEditor-editor .RichEditor-list1 .numbering,
.RichEditor-editor .RichEditor-list2 .numbering,
.RichEditor-editor .RichEditor-list3 .numbering,
.RichEditor-editor .RichEditor-list4 .numbering,
.RichEditor-editor .RichEditor-signBlock .numbering,
.RichEditor-editor .RichEditor-table .numbering,
.RichEditor-editor .RichEditor-unclassified .numbering,
.RichEditor-editor .RichEditor-preamble .clauseTitle,
.RichEditor-editor .RichEditor-clausePar1 .clauseTitle,
.RichEditor-editor .RichEditor-clausePar2 .clauseTitle,
.RichEditor-editor .RichEditor-clausePar3 .clauseTitle,
.RichEditor-editor .RichEditor-clausePar4 .clauseTitle,
.RichEditor-editor .RichEditor-list1 .clauseTitle,
.RichEditor-editor .RichEditor-list2 .clauseTitle,
.RichEditor-editor .RichEditor-list3 .clauseTitle,
.RichEditor-editor .RichEditor-list4 .clauseTitle,
.RichEditor-editor .RichEditor-signBlock .clauseTitle,
.RichEditor-editor .RichEditor-table .clauseTitle,
.RichEditor-editor .RichEditor-unclassified .clauseTitle {
	color: #4d4b59;
	background-color: transparent;
	font-weight: 400;
	padding: 0px 0px 0px 0px;
	margin-right: 0px;
}

.numbering {
	/*
	border: 1px solid #f9cb9c;
	border: 1px solid #f6b26b;
	*/
	border-radius: 5px;
	background-color: #f9cb9c;
	color: #2b2936;
	font-weight: 700;
	padding: 0px 5px 0px 5px;
	margin-right: 2px;
}

.clauseTitle {
	background-color: #d9ead3;
	/*border: 1px solid #b6d7a8;
	border: 1px solid #3c78d8;*/
	color: #2b2936;
	border-radius: 4px;
	font-weight: 700;
	padding: 0px 5px 0px 5px;
}

.RichEditor-editor .RichEditor-agrTitle {
	/*border-right: 1px dotted #aca9ba;*/
	border-left: 6px solid #E80064;
	font-size: 40px;
    font-weight: 700;
	padding: 30px 30px 30px 120px;
	background: url("assets/img/import-agrTitle.png") no-repeat center left;
	background-size: 101px auto;
	/*background-color: #f3f3f3;*/
}

.RichEditor-editor .RichEditor-exhTitle {
	border-top: 1px dotted #aca9ba;
	border-left: 6px solid #E80064;
	font-size: 40px;
    font-weight: 700;
	margin-top: 50px;
	padding: 30px 30px 30px 120px;
	background: url("assets/img/import-exhTitle.png") no-repeat center left;
	background-size: 101px auto;

}

.RichEditor-editor .RichEditor-secTitle1 {
	border-top: 1px dotted #aca9ba;
	/*border-right: 1px dotted #aca9ba;*/
	border-left: 6px solid #7243dd;
	background: url("assets/img/import-secTitle.png") no-repeat center left;
	background-size: 101px auto;
	/*background-color: #f6f6f6;
	border-left: 10px solid #7243dd;
	border-right: 10px solid #7243dd;
	background-color: #f3f0ff;*/
	font-size: 28px;
    font-weight: 700;
	padding: 20px 30px 20px 120px;
}

.RichEditor-editor .RichEditor-secTitle2 {
	/*
	border-left:10px dotted #7243dd;
	border-right: 10px dotted #7243dd;
	background-color: #f3f0ff;*/
	border-top: 1px dotted #aca9ba;
	/*border-right: 1px dotted #aca9ba;*/
	border-left: 6px solid #9671e5;
	background: url("assets/img/import-subsecTitle.png") no-repeat center left;
	background-size: 101px auto;
	/*background-color: #f9f9f9;*/
	font-size: 24px;
    font-weight: 700;
	padding: 20px 30px 20px 140px;
}

.RichEditor-editor .RichEditor-preamble {
	/*
	border-left: 10px solid #6d6a7a;
	border-right: 10px solid #6d6a7a;
	border-top: 1px dotted #cccccc;*/
	/*border-right: 1px dotted #aca9ba;*/
	border-left: 6px solid #6d6a7a;
	background: url("assets/img/import-preamble.png") no-repeat center left;
	background-size: 101px auto;
	padding: 10px 30px 10px 120px;
	color: #4d4b59;
}

.RichEditor-editor .RichEditor-signBlock {
	/*border-right: 1px dotted #aca9ba;*/
	border-left: 6px solid #62be87;
	background: url("assets/img/import-signBlock.png") no-repeat center left;
	background-size: 101px auto;
	/*
	border-left: 10px solid #62be87;
	border-right: 10px solid #62be87;*/
	padding: 20px 30px 20px 120px;
}

.RichEditor-editor .RichEditor-table {
	/*border-right: 1px dotted #aca9ba;*/
	border-top: 1px dotted #aca9ba;
	border-left: 6px solid #000000;
	font-weight: 700;
	color: #827f8f;
	background: url("assets/img/import-table.png") no-repeat center left;
	background-size: 101px auto;
	padding: 35px 30px 35px 180px;
}

.RichEditor-editor .RichEditor-clauseStartPar1,
.RichEditor-editor .RichEditor-clauseStartPar2,
.RichEditor-editor .RichEditor-clauseStartPar3,
.RichEditor-editor .RichEditor-clauseStartPar4 {
	border-top: 1px dotted #aca9ba;
	border-left: 6px solid #aca9ba;
	background: url("assets/img/import-clauseStartPar1.png") no-repeat center left;
	background-size: 101px auto;	
	padding: 20px 30px 20px 120px;
	color: #4d4b59;
}


.RichEditor-editor .RichEditor-clauseStartPar2 { padding: 20px 30px 20px 140px; }
.RichEditor-editor .RichEditor-clauseStartPar3 { padding: 20px 30px 20px 160px; }
.RichEditor-editor .RichEditor-clauseStartPar4 { padding: 20px 30px 20px 180px; }
/*background: url("assets/img/import-clauseStartPar.png") no-repeat center left; background-size: 101px auto;*/

.RichEditor-editor .RichEditor-clausePar1,
.RichEditor-editor .RichEditor-clausePar2,
.RichEditor-editor .RichEditor-clausePar3,
.RichEditor-editor .RichEditor-clausePar4 {
	border-left: 6px solid #aca9ba;
	background: url("assets/img/import-clausePar.png") no-repeat center left;
	background-size: 101px auto;	
	padding: 10px 30px 20px 120px;
	color: #4d4b59;
}

.RichEditor-editor .RichEditor-clausePar2 { padding: 10px 30px 20px 140px; }
.RichEditor-editor .RichEditor-clausePar3 { padding: 10px 30px 20px 160px; }
.RichEditor-editor .RichEditor-clausePar4 { padding: 10px 30px 20px 180px; }

.RichEditor-editor .RichEditor-list1,
.RichEditor-editor .RichEditor-list2,
.RichEditor-editor .RichEditor-list3,
.RichEditor-editor .RichEditor-list4 {
	border-left: 6px solid #aca9ba;
	background: url("assets/img/import-list.png") no-repeat center left;
	background-size: 101px auto;
	padding: 0px 30px 10px 120px;
	color: #4d4b59;
}

.RichEditor-editor .RichEditor-list2 { padding: 0px 30px 10px 140px; }
.RichEditor-editor .RichEditor-list3 { padding: 0px 30px 10px 160px; }
.RichEditor-editor .RichEditor-list4 { padding: 0px 30px 10px 180px; }

.RichEditor-editor .RichEditor-unclassified {
	border-left: 10px solid #f0f0f0;
	background-color: #f0f0f0;
	color: #827f8f;
	text-decoration: line-through;
	font-size: 13px;
	font-weight: 300;
	padding: 0px 30px 0px 120px;
}

/*
.RichEditor-editor .RichEditor-unstyled {
	border-left: 10px solid #D0252E;
	border-right: 10px solid #D0252E;
	background-color: #ffffff;
	color: #D0252E;
	font-size: 13ox;
	padding: 15px 20px;
}*/

.RichEditor-editor .public-DraftStyleDefault-pre {
background-color: rgba(0, 0, 0, 0.05);
font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
font-size: 16px;
padding: 20px;
}

.RichEditor-controls {
margin: 0px;
font-size: 16px;
font-weight: 600;
user-select: none;
}

.RichEditor-styleButton-agrTitle {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #E80064;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-agrTitle {
	background-color: #E80064;
	border-left: 5px solid #E80064;
	border-right: 5px solid #E80064;
	color: #ffffff;
}

.RichEditor-styleButton-preamble {
	background-color:transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #6d6a7a;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-preamble {
	background-color: #6d6a7a;
	border-left: 5px solid #6d6a7a;
	border-right: 5px solid #6d6a7a;
	color: #ffffff;
}

.RichEditor-styleButton-signBlock {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #62be87;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-signBlock {
	background-color: #62be87;
	border-left: 5px solid #62be87;
	border-right: 5px solid #62be87;
	color: #ffffff;
}

.RichEditor-styleButton-secTitle {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #7243dd;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-secTitle {
	background-color: #7243dd;
	border-left: 5px solid #7243dd;
	border-right: 5px solid #7243dd;
	color: #ffffff;
}

/*
.RichEditor-styleButton-secTitle2 {
	background-color: #ffffff;
	border-left: 5px solid #ffffff;
	border-right: 5px solid #9671e5;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-secTitle2 {
	background-color: #9671e5;
	border-left: 5px solid #9671e5;
	border-right: 5px solid #9671e5;
	color: #ffffff;
}*/

/*
.RichEditor-styleButton-clauseTitle {
	background-color: #1446A0;
	color: #ffffff;
	cursor: pointer;
	margin: 5px 4px;
	padding: 8px 10px;
	border-radius: 5px;
	font-size: 15px;
	display: inline-block;
}*/

.RichEditor-styleButton-clauseStartPar {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #aca9ba;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-clauseStartPar {
	background-color: #aca9ba;
	border-left: 5px solid #aca9ba;
	border-right: 5px solid #aca9ba;
	color: #ffffff;
}

.RichEditor-styleButton-clausePar {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #aca9ba;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-clausePar {
	background-color: #aca9ba;
	border-left: 5px solid #aca9ba;
	border-right: 5px solid #aca9ba;
	color: #ffffff;
}

.RichEditor-styleButton-list {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #aca9ba;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-list {
	background-color: #aca9ba;
	border-left: 5px solid #aca9ba;
	border-right: 5px solid #aca9ba;
	color: #ffffff;
}

.RichEditor-styleButton-unclassified {
	background-color: transparent;
	border-left: 5px solid transparent;
	border-right: 5px solid #f0f0f0;
	color: #4d4b59;
	cursor: pointer;
	margin: 2px 0px 2px 0px;
	padding: 10px 0px 10px 0px;
	width: calc(100% - 10px);
	border-radius: 0px;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.RichEditor-activeButton-unclassified {
	background-color: #f0f0f0;
	border-left: 5px solid #f0f0f0;
	border-right: 5px solid #f0f0f0;
	color: #aca9ba;
}

.RichEditor-styleBox {
	position: fixed;
	top: 0px;
	right: 17px;
	width: 260px;
	height: 100%;
	text-align: center;
	background-color: #f9f9f9;
	border-left: 1px #DDDDDD solid;
	z-index: 100;
	/*box-shadow: 0px 1px 2px -1px rgba(112, 61, 220, 0.3);*/
}

.customEditor div.DraftEditor-root {
	background-color: #ffffff;
	min-height: 80px;
	/*border: 1px solid #cac7d8;
	border-radius: 8px;*/
	padding: 15px;
	margin: 15px 0px 0px 0px;
}
.customEditor div.DraftEditor-editorContainer,
.customEditor div.public-DraftEditor-content {
	height: 100%;
	min-height: 80px;
}

.customEditor-section div.DraftEditor-root {
	background-color: #ffffff;
	/*min-height: 80px;*/
	/*border: 1px solid #cac7d8;
	border-radius: 8px;*/
	padding: 15px;
	margin: 15px 0px 0px 0px;
}
.customEditor-section div.DraftEditor-editorContainer,
.customEditor-section div.public-DraftEditor-content {
	height: 100%;
	/*min-height: 80px;*/
}


/* Mentioning Editor */
.MentionEditor-editor {
	min-height: 140px;
	color: #2b2936;
	background-color: #ffffff;
	box-sizing: border-box;
	border: 1px solid #dddddd;
	cursor: text;
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 10px;
	font-weight: 300;
	font-size: 16px;
	text-align: left;
}

.MentionEditor-additional {
	min-height: 66px;
	color: #2b2936;
	box-sizing: border-box;
	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 10px;
	margin-bottom: 3px;
	cursor: text;
	padding: 15px;
	font-weight: 300;
	font-size: 14px;
	text-align: left;
}

.MentionEditor-plain {
	color: #2b2936;
	background-color: #f9f9f9;
	cursor: text;
	padding: 18px;
	font-weight: 300;
	font-size: 14px;
	text-align: left;
}

.public-MentionEditor-content {
	height: 100%;
}

.MentionEditor-editor .public-DraftEditorPlaceholder-root {
	color: #827f8f;
	font-size: 16px;
	margin: 0px;
	padding: 0px;
}

.MentionEditor-additional .public-DraftEditorPlaceholder-root {
	color: #827f8f;
	font-size: 14px;
	margin: 0px;
	padding: 0px;
}

.MentionEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
	display: none;
}

.m6zwb4v,.m6zwb4v:visited{color:#575f67;cursor:pointer;display:inline-block;background:transparent;padding-left:2px;padding-right:2px;border-radius:2px;-webkit-text-decoration:none;text-decoration:none;font-weight: 700;}.m6zwb4v:hover,.m6zwb4v:focus{color:#677584;background:transparent;outline:0;}.m6zwb4v:active{color:#222;background:#455261;}
.mnw6qvm{border:1px solid #eee;position:absolute;min-width:220px;max-width:440px;background:#fff;border-radius:10px; border: 1px solid #dddddd ;cursor:pointer;padding-top:8px;padding-bottom:8px;z-index:2;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;box-sizing:border-box;-webkit-transform:scale(0);-ms-transform:scale(0);transform:scale(0);}
.m1ymsnxd{opacity:0;-webkit-transition:opacity 0.25s cubic-bezier(0.3,1.2,0.2,1);transition:opacity 0.25s cubic-bezier(0.3,1.2,0.2,1);}
.m126ak5t{opacity:1;}
.mtiwdxc{padding:7px 10px 3px 10px;-webkit-transition:background-color 0.4s cubic-bezier(.27,1.27,.48,.56);transition:background-color 0.4s cubic-bezier(.27,1.27,.48,.56);}.mtiwdxc:active{background-color:#dddddd;}
.myz2dw1{padding:7px 10px 3px 10px;-webkit-transition:background-color 0.4s cubic-bezier(.27,1.27,.48,.56);transition:background-color 0.4s cubic-bezier(.27,1.27,.48,.56);background-color:#f1f1f1;}.myz2dw1:active{background-color:#dddddd;}
.mpqdcgq{display:inline-block;margin-left:8px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;max-width:368px;font-size:0.9em;margin-bottom:0.2em;}
.m1mfvffo{display:inline-block;width:24px;height:24px;border-radius:12px;}